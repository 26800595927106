<div class="empty-view-wrap">
  <div class="empty-view-container-wrap">
    <div class="empty-view-container">
      <img *ngIf="cameraScan" src="/assets/img/loader-dark.gif" alt="">
      <zxing-scanner *ngIf="!cameraScan"   (camerasFound)="camerasFoundHandler($event)"
      [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']" (scanSuccess)="this.barcodeScanned.next($event)">
      </zxing-scanner>
      <div class="button-container">
        <button class="button button-highlight" (click)="cameraScan = !cameraScan">Toggle Camera</button>
      </div>
      <h4 class="saving">Waiting for Scan<span>.</span><span>.</span><span>.</span></h4>

      <input [hidden]="false" placeholder="Invoice Number" #input id="input" class="form-control" type="text"
        name="barcodeScanned" [ngModel]="parcelReference" id="barcodeScanned"
        (ngModelChange)="this.barcodeScanned.next($event)">

      <p class="error">{{error}}</p>
    </div>
  </div>
</div>


<div class="overlay" *ngIf="shipment">
  <div class="content">
    <h2>Route</h2>
    <h1>{{shipment.trip_id ? shipment.trip_id : 'Not on Trip'}}</h1>
    <div class="button-container">
      <button class="button button-highlight" (click)="reset()">close</button>
    </div>
  </div>
</div>