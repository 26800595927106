import { Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as axios from 'axios/dist/axios.min.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  barcodeScanned = new Subject<string>();
  cameraScan = true;
  parcelReference
  shipment;
  cameras;
  error;
  
  @ViewChild('input', { static: true }) input;

  constructor() {
    this.barcodeScanned.pipe(
      debounceTime(400))
      .subscribe(value => {
        this.scan(value);
      });
      const stagingUrl = 'https://picupstaging-webapi.azurewebsites.net/v1/'
      const productionUrl = 'https://picupprod-webapi.azurewebsites.net/v1/';
      const OTDCLstagingKey = 'business-d4835642-98d6-40b6-a81e-bc6637c4eb6d';
      const OTDCLproductionKey = 'business-8b1f02b1-69db-424d-8e5c-4b227182ce5e';
      axios.defaults.baseURL = productionUrl;
      axios.defaults.headers.common['api-key'] = OTDCLproductionKey;
  }

  camerasFoundHandler($event){
    console.log($event);
    // this.cameras= $event;
  }

  printWaybill(waybillNumbers) {
    return axios({
      method: 'POST',
      url: '/integration/download-waybills',
      responseType: 'arraybuffer',
      data: waybillNumbers
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
  }

  printWaybillsbyParcelReference(waybillNumbers){
    const number = waybillNumbers[0];
    return axios({
      method: 'GET',
      url: 'integration/download-waybills-by-parcel-reference/' + number,
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);
        
        setTimeout(
          function(){ 
          iframe.contentWindow.print();
          }, 1000);

          this.reset();
      })
  }



  searchLookups(orderLookup) {
    this.error = null;
    return axios
      .get(`/integration/order-by-business-reference/` + orderLookup)
      .then(
        response => {
          this.shipment = true;
          return response.data;
      })
      .catch(
        error => {
          if(error.response.data.message){
            this.error = error.response.data.message;
          }
         this.reset();
        }
      )
  }
  
  scan(reference) {
    this.printWaybillsbyParcelReference([reference]);
    // this.searchLookups(reference)
    //   .then(
    //     data => {
    //       this.shipment = data;
    //     }
    //   )
    //   .catch()
  }

  reset() {
    this.shipment = undefined;
    this.parcelReference = null;
    this.input.nativeElement.value = null;
    this.input.nativeElement.focus();
  }

  ngAfterViewInit() {
    this.input.nativeElement.focus();
  }

}

